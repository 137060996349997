import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FloatingLabelFormField,
  FormModule,
  SelectControl,
  useContainerProps,
  MaterialCheckbox
} from 'reactifi';
import { availableTagManagers, checkInput } from '../TagUtils';
import i18n from 'lib/i18n';

const TagManagerForm = (props) => {
  props = useContainerProps(props);
  const { currentEntity, errorMessage, selectMember } = props;

  const [hint, setHint] = useState(0);
  const [customErrorMessage, setCustomErrorMessage] = useState(null);
  const [context, setContext] = useState(0);

  const newTagEntry = (e = {}) => {
    if (e?.value) setContext(e.value);

    switch (e?.value) {
      case 'google':
        setHint(i18n.t('Ex. GTM-xxxxxxx'));
        break;
      case 'tealium':
        setHint(i18n.t('The unique ID should be in the following format: account/product/environment Ex. everfi/main/prod'));
        break;
      case 'tealium-first-party':
        setHint(i18n.t('The unique ID should be in the following format: https://subdomain.domain.com/PROFILE/ENVIRONMENT Ex: https://tags.tealiumecommerce.com/ecomm/prod'));
        break;
      case 'adobe':
        setHint(i18n.t('The Unique ID should be the source URL.  Ex. //assets.adobedtm.com/launch-ENa21cfed3f06f4ddf9690de8077b39e81-development.min.js'));
        break;
      default:
        setHint('');
    }
  };

  return (
    <FormModule
      onCancel={() => selectMember(null)}
      errorMessage={errorMessage || customErrorMessage}
      showAlertMessages={!errorMessage}
    >
      <FloatingLabelFormField
        caption={i18n.t('Enable any of the following Tag Management types')}
        onChange={newTagEntry}
        name="name"
      >
        <SelectControl
          data-object-attribute="name"
          data-object-id={currentEntity && currentEntity.id}
          data-object-type="tag_managers"
          options={availableTagManagers}
        />
      </FloatingLabelFormField>

      <FloatingLabelFormField
        name="unique_identifier"
        hintText={hint}
        caption={i18n.t('Unique Tag Manager ID')}
        onChange={(e) => checkInput(e, context, setCustomErrorMessage)}
        required={true}
      />

      <MaterialCheckbox name="enabled">
        {i18n.t('Enabled')}
      </MaterialCheckbox>
    </FormModule>
  );
};

TagManagerForm.propTypes = {
  currentEntity: PropTypes.object,
  errorMessage: PropTypes.string,
  selectMember: PropTypes.func
};

export default TagManagerForm;

import i18n from 'lib/i18n';

export const availableTagManagers = [
  { label: i18n.t('Google Tag Manager'), value: 'google' },
  { label: i18n.t('Tealium iQ (Third-Party URL)'), value: 'tealium' },
  {
    label: i18n.t('Tealium iQ (First-Party URL)'),
    value: 'tealium-first-party'
  },
  { label: i18n.t('Adobe Launch'), value: 'adobe' }
];

export const checkInput = (
  input = '',
  context = '',
  setErrorMessage = () => {}
) => {
  const tealiumFirstPartyRegex =
    /^https?:\/\/(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/;

  switch (context) {
    case 'adobe':
      if (!input.startsWith('//assets.adobetm.com')) {
        setErrorMessage(
          i18n.t('Address must start with //assets.adobedtm.com')
        );
        return;
      }
      break;
    case 'tealium-first-party':
      if (!tealiumFirstPartyRegex.test(input)) {
        setErrorMessage(
          i18n.t(
            'URL format must be valid: https://subdomain.domain.com/PROFILE/ENVIRONMENT Ex: https://tags.tealiumecommerce.com/ecomm/prod'
          )
        );
        return;
      }
      break;
  }

  setErrorMessage(null);
};
